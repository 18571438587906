<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title color="white">Nuovo timesheet</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <!-- Projects list, triggered with click on select progett in form -->
                <Transition name="fade-transition">
                    <div v-show="showProjects" class="project_modal">
                        <div class="projects_container">
                            <input type="text" placeholder="Cerca progetto" v-model="searchQuery" class="search_projects" />
                            <div class="projects_list">
                                <div
                                    v-for="project in searchedProjects"
                                    :key="project.projects_id"
                                    @click="setSelectedProject(project)"
                                    class="project"
                                    :class="setActiveProject(project, selectedProject)"
                                >
                                    {{ project.projects_name }}
                                </div>
                            </div>
                        </div>
                        <div @click="closeProjectSelection()" class="close_modal">Chiudi</div>
                    </div>
                </Transition>

                <form @submit.prevent="creaTimesheet()">
                    <ion-list class="fields">
                        <!-- Progetto -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title project_selection" position="floating">
                                    <span>
                                        Progetto
                                        <ion-text color="danger"><strong>*</strong></ion-text>
                                    </span>
                                    <div @click="openProjectSelection()" class="trigger">seleziona</div>
                                </div>
                                <div class="value">
                                    <div v-if="selectedProject">
                                        {{ selectedProject.projects_name }}
                                    </div>
                                    <div v-else>Nessun progetto selezionato</div>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Data -->
                        <ion-item v-if="$showTimesheetDateHour == 'false'" lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Data
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="date_start"
                                        display-format="DD/MM/YYYY"
                                        picker-format="DD/MM/YYYY"
                                        min="2021"
                                        max="2099"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="timesheet.data"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Ore totali -->
                        <ion-item v-if="$showTimesheetDateHour == 'false'" lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">Ore totali</div>
                                <div class="value">
                                    <ion-input type="number" min="0" step="any" v-model="timesheet.ore_totali"></ion-input>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Data inizio -->
                        <ion-item v-if="$showTimesheetDateHour == 'true'" lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Data inizio
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="date_start"
                                        display-format="DD/MM/YYYY"
                                        picker-format="DD/MM/YYYY"
                                        min="2021"
                                        max="2099"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="timesheet.data_inizio"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Ora inizio -->
                        <ion-item v-if="$showTimesheetDateHour == 'true'" lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Ora inizio
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="date_start"
                                        display-format="HH:mm"
                                        picker-format="HH:mm"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="timesheet.ora_inizio"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Data fine -->
                        <ion-item v-if="$showTimesheetDateHour == 'true'" lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Data fine
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="date_start"
                                        display-format="DD/MM/YYYY"
                                        picker-format="DD/MM/YYYY"
                                        min="2021"
                                        max="2099"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="timesheet.data_fine"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Ora fine -->
                        <ion-item v-if="$showTimesheetDateHour == 'true'" lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Ora fine
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="date_start"
                                        display-format="HH:mm"
                                        picker-format="HH:mm"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="timesheet.ora_fine"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Note -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">Note</div>
                                <div class="value">
                                    <ion-textarea type="text" rows="3" v-model="timesheet.note" placeholder="Descrizione attività svolta"> </ion-textarea>
                                </div>
                            </div>
                        </ion-item>

                        <div class="actions">
                            <button type="submit" class="btn_richiesta">
                                Inserisci timesheet
                            </button>
                        </div>
                    </ion-list>
                </form>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    modalController,
    IonList,
    IonItem,
    IonDatetime,
    IonTextarea,
    IonInput,
    IonText,
    IonIcon,
    actionSheetController,
} from "@ionic/vue";
import { arrowBackOutline } from "ionicons/icons";

import { defineComponent, ref, reactive, computed } from "vue";

import { LOCALSTORAGE_KEY } from "@/custom_config";

import moment from "moment";

import { openToast } from "@/services/toast";
import apiTimesheet from "@/services/timesheet";
import apiProgetti from "@/services/progetti";

export default defineComponent({
    name: "ModalNuovaNotaSpesa",
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonButton,
        IonList,
        IonItem,
        IonDatetime,
        IonTextarea,
        IonInput,
        IonText,
        IonIcon,
    },
    setup(props, context) {
        const start_date = new Date();
        const end_hour = new Date();
        const start_hour = new Date(end_hour);
        start_hour.setHours(start_hour.getHours() - 1);
        end_hour.setHours(start_hour.getHours() + 2);

        const userID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_user_id; //set userID in user field

        const timesheet = reactive({
            projects: null,
            data: start_date.toISOString(),
            ore_totali: 0,
            data_inizio: start_date.toISOString(),
            ora_inizio: start_hour.toISOString(),
            data_fine: start_date.toISOString(),
            ora_fine: end_hour.toISOString(),
            note: "",
        });

        const successResponse = ref(false);
        /**
         * ! Close modal after form data submit
         */
        async function closeModalOnSubmit(successResponse, res) {
            if (successResponse.value) {
                await modalController.dismiss(res);
            } else {
                await modalController.dismiss();
            }
        }

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        const showProjects = ref(false);
        function openProjectSelection() {
            showProjects.value = true;
        }

        function closeProjectSelection() {
            showProjects.value = false;
        }

        /**
         * ! Get projects list
         */
        const projects = ref([]);
        async function loadProgetti() {
            try {
                const res = await apiProgetti.getCommesse();
                if (res.status === 0) {
                    projects.value = res.data;
                } else {
                    openToast("Errore durante la richiesta dei progetti", "toast_danger");
                }
            } catch (error) {
                projects.value = [];
                openToast("Errore durante la richiesta dei progetti", "toast_danger");
            }
        }
        loadProgetti();

        // Ricerca nei progetti
        const searchQuery = ref("");
        const searchedProjects = computed(() => {
            const term = searchQuery.value.replace(/ /g, "");

            return projects.value.filter((project) => {
                if (project.projects_name) {
                    return (
                        project.projects_name
                            .toLowerCase()
                            .replace(/ /g, "")
                            .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                    );
                }
            });
        });

        const selectedProject = ref(null);
        function setSelectedProject(project) {
            //console.log(project);
            if (project) {
                searchQuery.value = "";
                selectedProject.value = project;
                showProjects.value = false;
            }
        }

        const setActiveProject = computed(() => {
            return (project, selectedProject) => {
                let className = "";
                if (selectedProject) {
                    if (project.projects_id === selectedProject.projects_id) {
                        className = "active_project";
                    }
                }
                return className;
            };
        });
        /**========================================================================================================================== */

        /**
         * ! Create new timesheet
         */
        async function creaTimesheet() {
            const timesheetDate = moment(timesheet.data).format("YYYY-MM-DD");
            //data ora inizio
            const startDatetime = moment(timesheet.data_inizio).format("YYYY-MM-DD") + " " + moment(timesheet.ora_inizio).format("HH:mm");
            //data ora fine
            const endDatetime = moment(timesheet.data_fine).format("YYYY-MM-DD") + " " + moment(timesheet.ora_fine).format("HH:mm");

            //Se non ho data mostro errore
            if (!timesheet.data) {
                openToast("La data è obbligatoria", "toast_danger");
                return;
            }

            const data = new FormData();
            data.append("timesheet_member", userID); //logged user
            //Selezione progetto
            if (selectedProject.value) {
                data.append("timesheet_project", selectedProject.value.projects_id);
            }
            //data ed ora inizio e fine
            if (process.env.VUE_APP_SHOW_TIMESHEET_DATE_HOUR === "true") {
                data.append("timesheet_start_time", startDatetime);
                data.append("timesheet_end_time", endDatetime);
            } else {
                //solo data ed ore totali
                data.append("timesheet_data", timesheetDate);
                data.append("timesheet_total_hours", timesheet.ore_totali);
            }
            data.append("timesheet_note", timesheet.note);

            //Display the values
            /* for (const value of data.entries()) {
                console.log(value[0] + ", " + value[1]);
            }
            return; */

            apiTimesheet
                .saveTimesheet(data)
                .then((response) => {
                    if (response.data.status == 8) {
                        openToast(response.data.message, "toast_danger");
                    } else {
                        const res = response.data.data[0];
                        successResponse.value = true;
                        closeModalOnSubmit(successResponse, res);
                    }
                })
                .catch((error) => {
                    //console.error(error);
                    openToast("Errore durante la creazione del timesheet", "toast_danger");
                })
                .finally(() => {
                    console.log("fine creazione timesheet");
                });
        }

        return {
            timesheet,
            creaTimesheet,
            closeModal,
            arrowBackOutline,
            //Gestione seleione progetti
            setSelectedProject,
            setActiveProject,
            searchedProjects,
            searchQuery,
            openProjectSelection,
            closeProjectSelection,
            selectedProject,
            showProjects,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative;
    min-height: 100%;
    padding: 16px;
}

.fields {
    width: 100%;
    border-radius: 4px;
    /*padding: 10px;*/
    padding: 16px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.field {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 8px;
}

.field_title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
    color: #6b7280;
}

ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --highlight-height: 0;
    --ripple-color: transparent;
    --background-activated: transparent;
    --background-activated-opacity: 0;
    margin-bottom: 4px;
}
ion-datetime,
ion-select {
    max-width: 100%;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    --padding-start: 8px;
}

ion-input,
ion-textarea {
    --placeholder-color: #6b7280;
    --padding-start: 8px !important;
    --padding-end: 8px !important;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    margin-top: 0;
}

.btn_richiesta {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 8px;
    text-align: center;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    background-color: rgb(22 163 74);
    color: #ffffff;
}

ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --padding-left: 0;
    --padding-right: 0;
    --inner-padding-end: 0;
}

/* .Selezione progetto */
.field_title.project_selection {
    display: flex;
    justify-content: space-between;
}

.field_title.project_selection .trigger {
    font-size: 10px;
    padding: 4px 8px;
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    text-transform: capitalize;
}
/* Modale selezione progetto */
.fade-transition-enter-active,
.fade-transition-leave-active {
    transition: opacity 0.25s ease;
}

.fade-transition-enter-from,
.fade-transition-leave-to {
    opacity: 0;
}
.project_modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    padding: 24px;
    align-items: center;
}
.close_modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #ffffff;
    background-color: #086fa3;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    margin-top: 16px;
}
.search_projects {
    width: 100%;
    border: 1px solid #c3c8cf;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 16px;
}
.search_projects::placeholder {
    color: #6b7280;
}
.search_projects:focus-visible {
    /* outline: 1px solid #509843;*/
    outline: 1px solid #334155;
}
.projects_container {
    width: 100%;
    height: 80%;
    background-color: #ffffff;
    border-radius: 4px;
    color: #000000;
    padding: 12px;
    display: flex;
    flex-direction: column;
}
.projects_list {
    overflow-y: scroll;
}

.project {
    padding: 6px;
}
.active_project {
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    font-weight: 500;
}

ion-button {
    --color: #ffffff;
}
</style>
